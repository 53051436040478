<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 실행기간 -->
          <c-datepicker
            name="period"
            :range="true"
            label="LBL0003786"
            defaultStart="-6M"
            defaultEnd="6M"
            v-model="period"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 결과 -->
          <c-select
            type="search"
            codeGroupCd="MIM_CHECK_RESULT_CD"
            itemText="codeName"
            itemValue="code"
            name="resultCd"
            label="LBL0003785"
            v-model="searchParam.resultCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 현황분류 -->
          <c-select
            :editable="editable"
            :comboItems="typeItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="equipmentType"
            label="LBL0003787"
            v-model="searchParam.equipmentType"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 관련법규 -->
          <c-multi-select
            :editable="editable"
            codeGroupCd="REGULATION_BILL_API_CD"
            itemText="codeName"
            itemValue="code"
            label="LBL0003610"
            name="relatedLawList"
            maxValues="3"
            v-model="searchParam.relatedLawList">
          </c-multi-select>
        </div>
      </template>
    </c-search-box>
    <div class="col-12">
      <!-- 설비 목록 -->
      <c-table
        ref="table"
        title="LBL0003696"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :hideBottom="true"
        :editable="editable"
        rowKey="key"
        @rowClick="rowClick"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <!-- 검색 -->
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            <!-- 기기정비 이력기록서 -->
            <c-btn label="LBL0003794" icon="print" @btnClicked="btnRecord" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div v-if="isCheck === '1'" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" topClass="topcolor-orange">
      <!-- 설비별 점검항목(실행일 : ) -->
      <!-- 설비에 대한 점검항목이 없습니다. -->
      <c-table
        ref="table"
        :title="`${$label('LBL0003795')} ${realDate})`"
        :columns="grid2.columns"
        :gridHeight="grid2.height"
        :data="record.equipmentResultList"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :hideBottom="true"
        :noDataLabel="$message('MSG0001069')"
        rowKey="checkItemSeq"
        cardClass="topcolor-orange"
      >
        <template slot="table-button">
          <!-- 점검결과 보고서 -->
            <c-btn label="LBL0003796" icon="print" @btnClicked="btnResult" />
        </template>
      </c-table>
    </div>
    <div v-if="isCheck === '2'" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- 설비 정비결과 -->
      <c-card class="cardClassDetailForm" title="LBL0003797" topClass="topcolor-orange" bgClass="">
        <template slot="card-detail">
          <div v-if="editable && record.inspResultAfterMemName" class="col-3">
            <!-- 정비결과 -->
            <c-label-text title="LBL0003617" :value="record.inspResultAfterMemName"></c-label-text>
          </div>
          <div v-if="editable && record.maintenanceTimeT" class="col-3">
            <!-- 정비시간 -->
            <c-label-text title="LBL0003764" :value="record.maintenanceTimeT"></c-label-text>
          </div>
          <div v-if="editable && record.stopDrivingTimeT" class="col-3">
            <!-- 운전정지시간 -->
            <c-label-text title="LBL0003776" :value="record.stopDrivingTimeT"></c-label-text>
          </div>
          <div v-if="editable && record.maintenanceQualifications" class="col-12">
            <!-- 정비자 자격사항 -->
            <c-label-text title="LBL0003766" :value="record.maintenanceQualifications"></c-label-text>
          </div>
          <div v-if="editable && record.maintenanceCause" class="col-12">
            <!-- 정비원인 -->
            <c-label-text title="LBL0003767" :value="record.maintenanceCause"></c-label-text>
          </div>
          <div v-if="editable && record.maintenanceContent" class="col-12">
            <!-- 정비내용 -->
            <c-label-text title="LBL0003768" :value="record.maintenanceContent"></c-label-text>
          </div>
          <div v-if="editable && record.maintenanceResultSummary" class="col-12">
            <!-- 정비결과요약 -->
            <c-label-text title="LBL0003769" :value="record.maintenanceResultSummary"></c-label-text>
          </div>
          <div v-if="editable && record.reviewOpinionOfMananger" class="col-12">
            <!-- 관리자의 검토의견 -->
            <c-label-text title="LBL0003770" :value="record.reviewOpinionOfMananger"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'hazop-target-equipment',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        year: '',
        minEquipmentCheckId: '',
        statusTypeName: '',
        realDate: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      inspections: [],
      selectedRow: {
        check: true,
        color: "primary",
        equipmentMaintainMonthList: [],
        selected: true,
        year: '',
      },
      typeItems: [
        { code: '1', codeName: '점검' },
        { code: '2', codeName: '정비' },
      ],
      flagItems: [
        { code: 'Y', codeName: '해당' },
        { code: 'N', codeName: '해당없음' },
      ],
      record: {
        // 점검쪽
        minEquipmentCheckId: '',
        plantCd: '',
        plantName: '',
        checkDeptCd: '',
        checkDeptName: '',  
        checkUserId: '',
        checkUserName: '',
        sopMocId: '',
        equipmentCheckKindCd: null,
        equipmentCheckKindNm: '',
        checkStatusCd: '',
        checkScheduleUserName: '', 
        checkScheduleUserId: '',
        checkScheduleDate: '',
        equipmentTypeCd: '', 
        equipmentCd: '', 
        equipmentName: '',
        inspectionCycleName: '',
        inspectionCycleCd: '',
        recentInspectionDate: '',
        relatedLaws: '',
        regUserId: '',
        chgUserId: '',
        noPlanResultEnrollFlag: 'N',
        checkResultCd: null,
        checkCompleteFlag: null,
        checkDate: '',
        equipmentResultList: [],
        // 정비쪽
        minEquipmentMaintenanceId: '',
        maintenanceStatusCd: null,
        equipmentCheckTypeCd: null,
        memDeptEntrantUserId: '',
        maintenanceDueDate: '',
        maintenanceDueUserId: '',
        maintenanceUserId: '',
        maintenanceQualifications: '',
        maintenanceDate: '',
        maintenanceResultSummary: '',
        inspResultAfterMemCd: null,
        maintenanceCompleteFlag: null,
        maintenanceCause: '',
        maintenanceContent: '',
        maintenanceTime: '',
        stopDrivingTime: '',
        reviewOpinionOfMananger: '',
      },
      grid: {
        columns: [
          {
            name: "statusTypeCd",
            field: "statusTypeCd",
            // 현황분류
            label: "LBL0003787",
            style: 'width:80px',
            type: 'tag',
            colorItems: [
              { stepperMstCd: 'Y', stepperMstNm: '점검', colorClass: 'green' },
              { stepperMstCd: 'N', stepperMstNm: '정비', colorClass: 'orange' },
            ],
            align: "center",
            sortable: false,
          },
          {
            name: "deptName",
            field: "deptName",
            // 점검부서/정비입회자
            label: "LBL0003798",
            style: 'width:100px',
            align: "center",
            sortable: false,
          },
          {
            name: "typeName",
            field: "typeName",
            // 점검/정비유형
            label: "LBL0003799",
            style: 'width:100px',
            align: "center",
            sortable: false,
          },
          
          {
            // 점검/정비
            label: 'LBL0003791',
            align: 'center',
            sortable: false,
            child: [
              {
                name: "realDateT",
                field: "realDateT",
                // 실행일
                label: "LBL0003792",
                style: 'width:110px',
                align: "center",
                sortable: false,
              },
              {
                name: "userName",
                field: "userName",
                // 관리자
                label: "LBLMANAGER",
                style: 'width:100px',
                align: "center",
                sortable: false,
              },
              {
                name: "resultName",
                field: "resultName",
                // 결과
                label: "LBL0003785",
                style: 'width:90px',
                align: "center",
                sortable: false,
              },
            ]
          },
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            // 관련법규
            label: "LBL0003610",
            style: 'width:250px',
            align: "left",
            sortable: false,
          },
        ],
        data: [],
        height: '300px'
      },
      grid2: {
        merge: [
          { index: 0, colName: "mergeSet" }
        ],
        columns: [
          {
            name: 'checkItemName',
            field: 'checkItemName',
            // 점검항목
            label: 'LBL0003699',
            style: 'width:200px',
            // type: 'textarea',
            align: 'left',
            sortable: false
          },
          {
            name: 'checkResult',
            field: 'checkResult',
            // 점검결과
            label: 'LBL0003607',
            align: 'left',
            style: 'width:250px',
            sortable: false
          },
          {
            name: 'checkWayName',
            field: 'checkWayName',
            // 검사방법
            label: 'LBL0003700',
            align: 'center',
            style: 'width:100px',
            sortable: false
          },
          {
            name: 'judgmentResultTypeName',
            field: 'judgmentResultTypeName',
            // 적합/부적합
            label: 'LBL0003701',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'decisionBasis',
            field: 'decisionBasis',
            // 판정기준
            label: 'LBL0003702',
            // type: 'textarea',
            style: 'width:200px',
            align: 'left',
            sortable: false
          },
          {
            name: 'checkCaution',
            field: 'checkCaution',
            // 점검시 안전사항
            label: 'LBL0003703',
            style: 'width:200px',
            align: 'left',
            sortable: false
          },
        ],
        data: [],
        height: '480px'
      },
      component: null,
      getUrl: '',
      listUrl: '',
      printUrl: '',
      printUrl2: '',
      isCheck: '0',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      realDate: '',
      searchParam: {
        // 설비점검 start
        safCheckTypeCd1: null,
        // 설비정비 start
        safCheckTypeCd2: null, 
        resultCd: null,
        startYmd: '',
        endYmd: '',
        detailFlag: 'N',
        noPlanResultEnrollFlag: null,
        relatedLawList: [],
        lawEquipmentFlag: null,
        equipmentType: null,
        year: '',
        // 설비점검 start
      },
      chkResultItems: [],
      chkRsltYnItems: [],
      safFacilityItems: [],
      title: '',
      period: ['',''],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.sop.min.equipment.maintain.getYear.url;
      this.printUrl = selectConfig.sop.min.equipment.maintain.print.url;
      this.printUrl2 = selectConfig.sop.min.equipment.maintain.print2.url;
      // code setting
      this.searchParam.year = this.$comm.getThisYear();
      if (this.popupParam.equipmentCd) {
        this.searchParam.equipmentCd = this.popupParam.equipmentCd
      }
      // list setting
      this.getList();
      this.rowClick({
        equipKey: this.popupParam.minEquipmentCheckId,
        statusTypeName: this.popupParam.statusTypeName,
        realDate: this.popupParam.realDate,
      })
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.record = {}
      this.$http.url = this.listUrl
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    rowClick(row) {
      if (row.statusTypeName === '점검') {
        this.getUrl = selectConfig.sop.min.equipment.plan.get.url;
        this.isCheck = '1';
      } else { 
        this.getUrl = selectConfig.sop.min.maintenance.plan.get.url;
        this.isCheck = '2';
      }
      this.realDate = this.$_.cloneDeep(row.realDate);
      this.$http.url = this.$format(this.getUrl, row.equipKey);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.record = _result.data;

        this.searchParam.plantCd = _result.data.plantCd
      });
    },
    btnRecord() {
      let thisVue = this;
      this.$http.url = this.printUrl
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request(
        _result => {
          let fileOrgNm = this.record.equipmentName + ' 기기정비이력기록서.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
    btnResult() {
      let thisVue = this;
      this.$http.url = this.printUrl2
      this.$http.type = 'GET';
      this.$http.param = {
        checkDate: this.realDate,
        checkDeptName: this.record.checkDeptName,
        checkUserName: this.record.checkUserName,
        plantName: this.record.plantName,
        equipmentCd: this.popupParam.equipmentCd,
        minEquipmentCheckId: this.record.minEquipmentCheckId
      };
      this.$http.request(
        _result => {
          let fileOrgNm = this.record.equipmentName + '(' + this.record.checkDate + ')' + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
  }
};
</script>
<!-- <style>
#chip {
  font-size: 15px !important;
  border-left: 20px;
  margin: 4px;
}
</style> -->